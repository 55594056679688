'use client';

import * as amplitude from '@amplitude/analytics-browser';
import { useEffect } from 'react';
import { AMPLITUDE_API_KEY } from './analytics';

export default function AmplitudeInit() {
  useEffect(() => {
    amplitude.init(AMPLITUDE_API_KEY, {
      serverUrl: 'https://amp-prx.dialectapi.to/2/httpapi',
      defaultTracking: {
        pageViews: {
          trackHistoryChanges: 'pathOnly',
          eventType: 'Page View',
        },
      },
    });
  }, []);

  return null;
}
