import React, { createContext, useContext, useEffect, useState } from 'react';
import { DeviceContext } from './DeviceContext';

const defaultValue = {
  open: false,
  setOpen: (open: boolean) => {},
  modalEnabled: false,
  setModalEnabled: (open: boolean) => {},
};

export const QRCodeContext = createContext(defaultValue);

export const QRCodeProvider = ({ children }: { children: React.ReactNode }) => {
  const { device } = useContext(DeviceContext);
  const [open, setOpen] = useState(false);
  const [modalEnabled, setModalEnabled] = useState(false);
  const shouldBeEnabled = !device?.phone && !device?.tablet;

  useEffect(() => {
    if (!shouldBeEnabled) return;
    setModalEnabled(true);
  }, [shouldBeEnabled]);

  return (
    <QRCodeContext.Provider
      value={{
        open,
        setOpen,
        modalEnabled,
        setModalEnabled,
      }}
    >
      {children}
    </QRCodeContext.Provider>
  );
};
