import * as amplitude from '@amplitude/analytics-browser';

export const AMPLITUDE_API_KEY =
  process.env.NODE_ENV === 'production'
    ? 'e436f961bacbfead001cc752d2659174'
    : 'fed3a66c411ba5ba2a7ffb7eded684d1';

export function analyticsTrack(
  eventName: string,
  params: { [key: string]: any }
) {
  const trackParams = {
    page_domain: (typeof location !== 'undefined' && location.hostname) || '',
    page_location: (typeof location !== 'undefined' && location.href) || '',
    page_path: (typeof location !== 'undefined' && location.pathname) || '',
    page_title: (typeof document !== 'undefined' && document.title) || '',
    page_url:
      (typeof location !== 'undefined' && location.href.split('?')[0]) || '',
    ...params,
  };

  return amplitude.track(eventName, trackParams);
}
