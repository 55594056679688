import { isMobileResult } from 'ismobilejs/types';
import React, { createContext } from 'react';

type DeviceType = isMobileResult;

const defaultValue = {
  device: {
    phone: true,
    tablet: false,
    apple: { device: false, tablet: false, phone: false },
    android: { device: false, tablet: false, phone: false },
  },
};

export const DeviceContext = createContext(defaultValue);

export const DeviceProvider = ({
  device,
  children,
}: {
  device: DeviceType;
  children: React.ReactNode;
}) => {
  return (
    <DeviceContext.Provider
      value={{
        device,
      }}
    >
      {children}
    </DeviceContext.Provider>
  );
};
