import '@dialectlabs/react-ui/index.css';
import '../styles/globals.css';
import type { AppProps } from 'next/app';
import { QRCodeProvider } from '../components/context/QRCodeContext';
import { DeviceProvider } from '../components/context/DeviceContext';
import Head from 'next/head';
import { config } from '@fortawesome/fontawesome-svg-core';
import '@fortawesome/fontawesome-svg-core/styles.css';
import AmplitudeInit from '../shared/utils/AmplitudeInit';
config.autoAddCss = false;

export default function App({ Component, pageProps }: AppProps) {
  return (
    <>
      <DeviceProvider device={pageProps.isMobile}>
        <QRCodeProvider>
          <Head>
            <meta
              name="viewport"
              content="initial-scale=1.0, width=device-width"
            />
          </Head>
          <Component {...pageProps} />
        </QRCodeProvider>
      </DeviceProvider>
      <AmplitudeInit />
    </>
  );
}
